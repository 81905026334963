enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_THEME_MODE_ACTION = 'setThemeModeAction',

  // EXS
  /* User store */
  PASSWORD = 'setUserPassword',
  AUTO_LOGIN = 'setUserAuthenticationData',
  GET_PROFILE = 'getUserProfile',
  SET_SESSION_AUTH = 'setSessionToken',
  UPDATE_PROFILE = 'updateUserProfile',
  UPDATE_ADDRESS = 'updateCompanyAddress',

  /* Company store */
  GET_COMPANY_DETAILS = 'getCompanyDetails',

  /* Invoices */
  GET_INVOICES = "getInvoices",
  UPLOAD_INVOICE = "uploadInvoice",
  DELETE_INVOICE = "DELETE_INVOICE",
  EXTRACT_INVOICE = "EXTRACT_INVOICE",
  GET_INVOICE = "GET_INVOICE",
  READ_INVOICE = "READ_INVOICE",
  SAVE_INVOICE = "SAVE_INVOICE",
  UPDATE_INVOICE_STATUS = "UPDATE_INVOICE_STATUS",
  CREATE_LEDES = "CREATE_LEDES",
  UNARCHIVE_INVOICE = "UNARCHIVE_INVOICE",
  GET_SSO_CREDS = "GET_SSO_CREDS",
  SSO_LOGIN = "SSO_LOGIN",
  GET_MATTERS = "GET_MATTERS",
  GET_BL_TIMEKEEPERS = "GET_BL_TIMEKEEPERS",
  BULK_ACTIONS = "BULK_ACTIONS",
  DUP_CHECK = "DUP_CHECK",
  GET_INVOICE_STATUS_COUNT = "GET_INVOICE_STATUS_COUNT",
  GET_INVOICE_HISTORICAL_DATA = "GET_INVOICE_HISTORICAL_DATA",
  GET_PROFILE_COMPLETION = "GET_PROFILE_COMPLETION",
  UPDATE_INVOICE_FILE = "UPDATE_INVOICE_FILE",
  ARCHIVE_INVOICE = "ARCHIVE_INVOICE",
  GET_LAWFIRM_LOCATIONS = "GET_LAWFIRM_LOCATIONS",
  RESEND_VERIFICATION = "RESEND_VERIFICATION",
  PUSH_LEDES = "PUSH_LEDES",
  GET_LAWFIRMS = "GET_LAWFIRMS",
  GET_LAWYERS_BY_LF = "GET_LAWYERS_BY_LF",
  API_COMPLETION = 'apiCompletion',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_THEME_MODE_MUTATION = 'setThemeModeMutation',

  // EXS
  /* User */
  SET_PASSWORD = 'setPassword',
  SET_PROFILE = 'setUserProfile',
  APPEND_TOKEN_DATA = 'appendTokenData',
  SET_MESSAGE = 'setMessage',
  API_COMPLETION_STATUS = 'apiCompletionStatus',

  /* Company */
  SET_COMPANY_DETAILS = 'setCompanyDetails',

  /* Invoices */
  SET_INVOICE_LIST = "setInvoiceList",
  SET_INVOICE = "SET_INVOICE",
  FLUSH_MESSAGE = "FLUSH_MESSAGE",
  SET_TEMP_PATH = "SET_TEMP_PATH",
  SET_SAVE_FLAG = "SET_SAVE_FLAG",
  SET_SSO_CREDS = "SET_SSO_CREDS",
  SET_MATTERS_LIST = "SET_MATTERS_LIST",
  SET_BL_TIMEKEEPERS = "SET_BL_TIMEKEEPERS",
  SET_ERROR_SSO = "SET_ERROR_SSO",
  SET_INVOICE_DUP_LIST = "SET_INVOICE_DUP_LIST",
  SET_INVOICE_COUNT = "SET_INVOICE_COUNT",
  SET_INVOICE_HISTORICAL_DATA = "SET_INVOICE_HISTORICAL_DATA",
  SET_PROFILE_COMPLETION = "SET_PROFILE_COMPLETION",
  GLOBAL_INV_UPLOAD_FLAG = "GLOBAL_INV_UPLOAD_FLAG",
  SET_LAWFIRM_LOCATION_LIST = "SET_LAWFIRM_LOCATION_LIST",
  SET_LAWFIRMS = "SET_LAWFIRMS",
  SET_LAWYERS_BY_LF = "SET_LAWYERS_BY_LF"
}

export { Actions, Mutations };
