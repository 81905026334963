import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

export interface UserProfileInfo {
  userProfile: UserProfile;
}

export interface UserProfile {
  avatar: string;
  first_name: string;
  last_name: string;
  emailAddress: string;
  company: string;
  contactPhone: string;
  companySite: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communications: {
    email: boolean;
    phone: boolean;
  };
  allowMarketing: boolean;
}

interface ApiPayload {
  status: boolean;
  api: string;
}

@Module
export default class UserModule extends VuexModule implements UserProfileInfo {
  userProfile = {} as UserProfile;

  /**
   * Get current user object
   * @returns User
   */
  get currentUserProfile(): UserProfile {
    return this.userProfile;
  }

  @Mutation
  [Mutations.SET_PROFILE](user) {
    const userData = user.user;

    this.userProfile.first_name = userData.first_name;
    this.userProfile.last_name = userData.last_name;
    this.userProfile.emailAddress = userData.email;

    if (typeof userData.phone !== 'undefined' && userData.phone !== null) {
      this.userProfile.contactPhone = userData.phone;
    }

    if (typeof userData.company !== 'undefined') {
      this.userProfile.company = userData.company;
    }
  }

  @Action
  [Actions.GET_PROFILE](credentials) {

    return ApiService.get('user', `?email=${credentials.email}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROFILE, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.API_COMPLETION](payload: ApiPayload) {
    let api_req_arr = JSON.parse(window.localStorage.getItem('api_req_arr') || '[]');
    api_req_arr = api_req_arr.filter(item => !item.api.includes(payload.api)); // Corrected the variable to use api_req_arr

    window.localStorage.setItem('api_req_arr', JSON.stringify(api_req_arr));

    if (api_req_arr.length === 0) {
      // Notify the store that all API requests are completed
      store.commit(Mutations.API_COMPLETION_STATUS, true);
    }
  }

  @Mutation
  [Mutations.API_COMPLETION_STATUS](_flag: boolean) { }
}
